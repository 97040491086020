import React from 'react';

const WaitlistDocuments = ({ documents }) => {
  return (
    <ul className="pl-4 mt-3">
      <div className="row mx-0">
        {documents.map(document => {
          return (
            <div key={document.attributes.title} className="col-lg-6">
              <li>
                <a
                  href={`${document.attributes.document_url}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {document.attributes.title}
                </a>
              </li>
            </div>
          );
        })}
      </div>
    </ul>
  );
};

export default WaitlistDocuments;
