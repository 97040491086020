import React from 'react';
import WaitlistStatusCard from './Waitlists/WaitlistStatusCard';
import WaitlistPageMeta from './Waitlists/WaitlistPageMeta';
import FullPropertyDetails from './Properties/FullPropertyDetails';
import PropertyImagesSection from './Properties/PropertyImagesSection';
import WaitlistDocuments from './Waitlists/WaitlistDocuments';

const WaitlistPage = props => {
  if (!props.waitlistData.waitlist) {
    return null;
  }

  const {
    waitlistData: {
      waitlist: { attributes },
      properties,
      locations,
      propertyImages,
      documents
    },
    applicantId,
    applicantHouseholdSize,
    applicantPreferences,
    joined,
    joinWaitlist,
    goBack
  } = props;

  return (
    <div className="pl-lg-5 mt-5">
      <div className="waitlist-detail-header row mx-0">
        <div className="col-lg-6 pl-0">
          <h1 className="green-text">{attributes.name}</h1>
        </div>
        <div className="col-lg-3 offset-lg-3 d-flex justify-content-end px-0">
          <div className="w-100">
            {applicantId && (
              <button
                className="btn btn-green mr-1 px-4 w-100"
                onClick={joinWaitlist}
                disabled={joined || !attributes.open}>
                Add me to Waitlist
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="my-4 row mx-0">
        <div className="col-lg-5 px-0">
          <WaitlistStatusCard {...attributes} />
        </div>
      </div>
      <WaitlistPageMeta
        attributes={attributes}
        applicantPreferences={applicantPreferences}
        applicantHouseholdSize={applicantHouseholdSize}
        applicantId={applicantId}
      />
      <div className="card mb-5">
        <h2 className="p-4 mb-0">Properties</h2>
        {properties.map((property, index) => {
          return (
            <div
              key={property.attributes.name}
              className={`${index % 2 === 0 && 'gray-card-bg'} p-4`}>
              <FullPropertyDetails property={property} locations={locations} />
            </div>
          );
        })}
      </div>
      <div className="card mb-5 p-4">
        <h2 className="mb-0">Waitlist Documents</h2>
        <WaitlistDocuments documents={documents} />
      </div>
      <PropertyImagesSection propertyImages={propertyImages} />
      <div className="row mx-0 mb-5">
        <div className="col-lg-2">
          {props.goBack && (
            <button className="btn min-btn mr-1 px-4 w-100" onClick={goBack}>
              Back
            </button>
          )}
        </div>
        <div className="col-lg-3 offset-lg-7 d-flex justify-content-end px-0">
          <div className="w-100">
            {applicantId && (
              <button
                className="btn btn-green mr-1 px-4 w-100"
                onClick={joinWaitlist}
                disabled={joined || !attributes.open}>
                Add me to Waitlist
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistPage;
