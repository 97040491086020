import React from 'react';

const WaitlistStatusCard = ({ open, avg_wait_time, avg_wait_time_units }) => {
  return (
    <div className="card card-shadow">
      <div className="d-flex justify-content-center mx-0">
        <div className="badge-wrap mx-2 my-3">
          <span className="badge left-badge green">Current Status</span>
          <span className="badge right-badge">
            {open === true ? 'Open' : 'Closed'}
          </span>
        </div>
        <div className="badge-wrap mx-2 my-3">
          <span className="badge left-badge black">Estimated Wait</span>
          <span className="badge right-badge">
            {avg_wait_time || 'N/A'} {avg_wait_time_units}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WaitlistStatusCard;
