import React from 'react';
import PropertyBuildingInfo from './PropertyBuildingInfo';

const FullPropertyDetails = props => {
  const {
    property: { attributes, relationships },
    locations
  } = props;

  const propertyAttributes = {
    accessibility_features: 'Accessibility',
    pets: 'Pets',
    amenities: 'Amenities',
    application_fee: 'Application Fee',
    security_deposit: 'Security Deposit',
    background_checks: 'Background Checks',
    utilities: 'Utilities residents pay'
  };

  return (
    <div className="row mx-0">
      <div className="col-md-5">
        <PropertyBuildingInfo
          attributes={attributes}
          relationships={relationships}
          locations={locations}
        />
      </div>
      <div className="col-md-7">
        {Object.keys(propertyAttributes).map(attribute => {
          return (
            <h4 key={attribute}>
              {propertyAttributes[attribute]}:{' '}
              {Array.isArray(attributes[attribute]) ? (
                <span className="font-weight-normal">
                  {attributes[attribute].length > 0
                    ? attributes[attribute].join(', ')
                    : `No ${attribute.replace(/_/g, ' ')}`}
                </span>
              ) : (
                <span className="font-weight-normal">
                  {attributes[attribute]
                    ? `$${attributes[attribute]}`
                    : `No ${attribute.replace(/_/g, ' ')}`}
                </span>
              )}
            </h4>
          );
        })}
      </div>
    </div>
  );
};

export default FullPropertyDetails;
