import React from 'react';

const WaitlistPageMeta = ({
  applicantPreferences,
  applicantHouseholdSize,
  attributes
}) => {

  const mappedPreferences = {
    Displaced: 'displaced',
    'Working Family': 'working_family',
    Disabled: 'disabled',
    'Victim of Domestic Violence': 'domestic_violence',
    Homeless: 'homeless',
    Elderly: 'senior_citizen',
    'I require the use of a mobility device': 'mobility_device'
  };

  const matchedPreferences = () => {

    if (!applicantPreferences) {
      return 'No Preferences';
    }
    const matches = attributes.preferences.reduce((acc, pref) => {
      if (applicantPreferences[mappedPreferences[pref]] === true) {
        acc.push(pref);
      }
      return acc;
    }, []);

    if (matches.length === 0) {
      return 'No Preferences';
    }
    return matches.join(', ');
  };

  const applicantIncomeLimit = attributes.household_income_limits.find(
    incomeLimit => {
      return incomeLimit.household_size === applicantHouseholdSize;
    }
  );

  return (
    <div>
      <div className="my-5">
        <h4>
          Waitlist Type:{' '}
          <span className="font-weight-normal">
            {attributes.waitlist_types.join(', ')}
          </span>
        </h4>
        <h4>
          Bedrooms:{' '}
          <span className="font-weight-normal">
            {attributes.unit_groups.join(', ')}
          </span>
        </h4>
        <h4>
          Applicant Requirements:{' '}
          <span className="font-weight-normal">
            {attributes.applicant_requirements.join(', ')}
          </span>
        </h4>
        <h4>
          Preference Characteristics I Match:{' '}
          <span className="font-weight-normal">{matchedPreferences()}</span>
        </h4>
        <h4>
          Yearly Minimum Income for My Household Size:{' '}
          <span className="font-weight-normal">
            {applicantIncomeLimit &&
              `$${applicantIncomeLimit.income_min.toLocaleString()}`}
          </span>
        </h4>
        <h4>
          Yearly Maximum Income for My Household Size:{' '}
          <span className="font-weight-normal">
            {applicantIncomeLimit &&
              `$${applicantIncomeLimit.income_max.toLocaleString()}`}
          </span>
        </h4>
      </div>
      <div className="my-5">
        <h2>Waitlist Description</h2>
        <p>{attributes.description}</p>
      </div>
    </div>
  );
};

export default WaitlistPageMeta;
